import { CircularProgress, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import {
  ErrorMessage,
  Layout,
  NavigationButtons,
  ScreenTitle,
  TextInput,
} from "components";
import { endpoints } from "config";
import { useOrders, useRequest, useShoppingCart } from "hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AnimatedPage from "routing/AnimatedPage";
import { RootState, activeOrderActions, shoppingCartActions } from "state";
// import { RootState, shoppingCartActions } from "state";
import { formatCurrency, purchaseEvent } from "utils";
import { color_theme } from "../../color-theme";
import DeliveryInfo from "./DeliveryInfo";
import LoadingOverlay from "./LoadingOverlay";
import ModalConfirmAge from "./ModalConfirmAge";
import ProductItem from "./ProductItem";
import { texts } from "./constants";

const OrderConfirmation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const {
    cartQuantities,
    fetchPricing,
    errorMessageShoppingCart,
    clearCupomErrorMessage,
  } = useShoppingCart();
  const { token } = useSelector((state: RootState) => state.auth);
  const { errorMessage } = useOrders();

  const [fumicoLoading, setFumicoLoading] = useState<boolean>(false);
  const [cupom, _setCupom] = useState<string>("");

  const setCupom = (val: string) => {
    _setCupom(val);

    clearCupomErrorMessage();

    if (!val) {
      fetchPricing();
    }
  };

  const {
    subtotal,
    subtotal_integral,
    products,
    fullprice,
    fullprice_integral,
    shipping,
    discount_from,
    // calculated_discount,
    // is_promo_eligible,
  } = useSelector((state: RootState) => state.shoppingCart);

  const { order } = useSelector((state: RootState) => state.activeOrder);

  useEffect(() => {
    if (!token || Object.keys(cartQuantities).length < 1) {
      dispatch(activeOrderActions.setIsOrderActive(false));
      navigate("/");
    }
    // eslint-disable-next-line
  }, [token, cartQuantities]);

  useEffect(() => {
    dispatch(activeOrderActions.setIsOrderActive(true));
  }, [dispatch]);

  // const setObservation = (e: any) => {
  //   dispatch(shoppingCartActions.setObservation(e.target.value));
  // };

  const { patch } = useRequest();

  const cancelOrder = async () => {
    if (!order) return;
    await patch(endpoints.status(order.id), { status: "CANCELLED" });
  };

  const next = async () => {
    setOpen(true);
  };

  const cancel = async () => {
    cancelOrder();
    navigate("/");
    dispatch(activeOrderActions.setIsOrderActive(false));
    dispatch(shoppingCartActions.cleanShoppingCart());
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleCoupon = async (
    value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setLoading(true);
    await fetchPricing(value.target.value);
    setLoading(false);
  };

  useEffect(() => {
    purchaseEvent();
  }, []);

  useEffect(() => {
    const updatePricing = async () => {
      try {
        setLoading(true);
        await fetchPricing();
      } finally {
        setLoading(false);
      }
    };

    void updatePricing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Layout>
        {fumicoLoading && <LoadingOverlay />}

        <AnimatedPage>
          <>
            <ErrorMessage text={error} />

            <ModalConfirmAge
              open={open}
              setOpen={setOpen}
              loading={fumicoLoading}
              setLoading={setFumicoLoading}
              setError={setError}
            />
            <ErrorMessage text={errorMessage} />
            <ScreenTitle>{texts.screenTitle}</ScreenTitle>

            <Box height="100%" mt="24px" width="100%">
              {products.map((product) => (
                <div key={product.id}>
                  <ProductItem
                    {...product}
                    quantity={cartQuantities[product.id] || 0}
                  />
                  <Box
                    sx={{
                      position: "relative",
                      width: "100vw",
                      left: -32,
                      borderBottom: "1px solid #000000",
                    }}
                  />
                </div>
              ))}
              <Box
                sx={{
                  fontWeight: 300,
                  fontSize: 16,
                  borderLeft: "4px solid #000000",
                  pl: 2,
                  my: 5,
                  fontFamily: "Montserrat",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    mb: 2,
                  }}
                >
                  <Box
                    sx={{
                      fontFamily: "Montserrat",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "16px",
                      lineHeight: "20px",
                      mr: 1,
                    }}
                  >
                    subtotal:
                  </Box>
                  <Box
                    sx={{
                      fontFamily: "Montserrat",
                      fontStyle: "normal",
                      fontWeight: 300,
                      fontSize: "16px",
                      lineHeight: "20px",
                    }}
                  >
                    {subtotal_integral && subtotal_integral !== subtotal && (
                      <span
                        style={{
                          textDecoration: "line-through",
                          color: color_theme.text.black.disabled,
                          marginRight: 8,
                        }}
                      >
                        {formatCurrency(subtotal_integral)}
                      </span>
                    )}
                    {formatCurrency(subtotal)}
                  </Box>
                </Box>
                {/* {is_promo_eligible?.length && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      // color: "#0AC499",
                      color: "#02AEDD",
                      mb: 2,
                    }}
                  >
                    <Box
                      sx={{
                        fontFamily: "Montserrat",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "13px",
                        lineHeight: "20px",
                        mr: 1,
                      }}
                    >
                      Você recebeu {is_promo_eligible?.length}{" "}
                      {is_promo_eligible?.length > 1 ? "brindes" : "brinde"}{" "}
                      grátis!
                    </Box>
                  </Box>
                )} */}

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    color:
                      shipping === 0 ? "#0AC499" : color_theme.text.black.main,
                  }}
                >
                  <Box
                    sx={{
                      fontFamily: "Montserrat",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "16px",
                      lineHeight: "20px",
                      mr: 1,
                    }}
                  >
                    frete:
                  </Box>
                  <Box
                    sx={{
                      fontFamily: "Montserrat",
                      fontStyle: "normal",
                      fontWeight: 300,
                      fontSize: "16px",
                      lineHeight: "20px",
                    }}
                  >
                    {shipping ? formatCurrency(shipping) : "grátis"}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  mb: 5,
                }}
              >
                <Box
                  sx={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "16px",
                    lineHeight: "20px",
                    mr: 1,
                  }}
                >
                  total:
                </Box>
                <Box
                  sx={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 300,
                    fontSize: "16px",
                    lineHeight: "20px",
                  }}
                >
                  {fullprice_integral && fullprice_integral !== fullprice && (
                    <span
                      style={{
                        textDecoration: "line-through",
                        color: color_theme.text.black.disabled,
                        marginRight: 8,
                      }}
                    >
                      {formatCurrency(fullprice_integral)}
                    </span>
                  )}
                  {formatCurrency(fullprice)}
                </Box>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "16px",
                    lineHeight: "20px",
                    fontFamily: "Montserrat",
                  }}
                >
                  {texts.addCupom}
                </Typography>
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    height: "60px",
                  }}
                >
                  <TextInput
                    name="adicionar cupom"
                    autoComplete="cupom_fumico"
                    placeholder="código do cupom"
                    onChange={(e: any) => setCupom(e.target.value)}
                    afterDelay={handleCoupon}
                    sx={{
                      input: {
                        color: color_theme.text.black.main,
                        fontWeight: 300,
                      },
                    }}
                    disabled={loading}
                    value={cupom}
                    includeClearButton
                  />
                  {loading && (
                    <Box
                      sx={{
                        position: "absolute",
                        right: "3%",
                        width: "30px",
                        height: "30px",
                        marginBottom: "15px",
                      }}
                    >
                      <CircularProgress size={28} />
                    </Box>
                  )}
                </Box>
                <Box mb={5}>
                  {errorMessageShoppingCart ? (
                    <ErrorMessage text={errorMessageShoppingCart} />
                  ) : (
                    <></>
                  )}
                  {!errorMessageShoppingCart &&
                  discount_from === "coupon" &&
                  subtotal_integral &&
                  subtotal !== subtotal_integral ? (
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: "12px",
                        lineHeight: "15px",
                        color: "#0AC499",
                      }}
                    >
                      *cupom aplicado com sucesso!
                    </Typography>
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  position: "relative",
                  width: "100vw",
                  left: -32,
                  borderBottom: "1px solid #000000",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mb: "41px",
                  pb: 5,
                  pt: 5,
                }}
              >
                <DeliveryInfo />
              </Box>
            </Box>
          </>
        </AnimatedPage>
      </Layout>

      <NavigationButtons
        leftTitle={texts.cancel}
        leftAction={cancel}
        rightTitle={texts.next}
        rightAction={next}
      />
    </>
  );
};

export default OrderConfirmation;
