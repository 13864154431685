import { FC, useEffect } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import { useSelector } from "react-redux";
import { RootState } from "state";

interface CarouselPromoProps {
  setCategory: any;
  setSubcategory: any;
  scrollTo: any;
}

const CarouselPromo: FC<CarouselPromoProps> = ({
  setCategory,
  setSubcategory,
  scrollTo,
}) => {
  useEffect(() => {}, []);
  const banners = useSelector((state: RootState) => state.banners.banners);
  const loading = useSelector((state: RootState) => state.banners.loading);

  const handleClick = (el: any) => {
    scrollTo();
    setCategory(el.category);
    setSubcategory(el.subcategory);
  };

  const items = banners?.map((el: any) => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <div
        key={el.id}
        style={{
          marginRight: "8px",
          marginLeft: "8px",
          width: "95%",
          height: "198px",
          background: `url(${el.image_url})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
        onClick={() => handleClick(el)}
      />
    </Box>
  ));

  return (
    <Box
      sx={{
        minHeight: "198px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {!loading ? (
        <AliceCarousel
          mouseTracking
          items={items}
          disableDotsControls
          disableButtonsControls
          infinite
          controlsStrategy="alternate"
          paddingLeft={20}
          paddingRight={20}
          animationDuration={1500}
          autoPlay={true}
          autoPlayInterval={2000}
          autoPlayStrategy="none"
        />
      ) : (
        <CircularProgress
          color="inherit"
          sx={{ position: "relative", top: 3 }}
        />
      )}
    </Box>

    // <Carousel
    //   infiniteLoop
    //   emulateTouch={false}
    //   interval={3000}
    //   // autoPlay
    //   showThumbs={false}
    //   showStatus={false}
    //   showIndicators={false}
    //   showArrows={false}
    //   centerMode
    //   centerSlidePercentage={85}
    // >
    //   {sales.map((promo) => (
    //     <div
    //       key={promo.id}
    //       style={{
    //         marginRight: "8px",
    //         marginLeft: "8px",
    //         width: "343px",
    //         height: "198px",
    //         background: `url(${promo.image_url})`,
    //         backgroundSize: 'cover',
    //         backgroundPosition: 'center center'
    //       }}
    //       onClick={() => handleClick(promo)}
    //      />
    //   ))}
    // </Carousel>
  );
};
export default CarouselPromo;
