import Box from "@mui/material/Box";
import { Layout, NavigationButtons, StepperStatus } from "components";
import { format } from "date-fns";
// import { Badge, Button } from "@mui/material";
// import { useChat, useOrderStatus } from "hooks";
import { useOrderStatus } from "hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AnimatedPage from "routing/AnimatedPage";
import { RootState, shoppingCartActions } from "state";
import BoxInfo from "./BoxInfo";
import CodeDelivery from "./CodeDelivery";
import { images, texts } from "./constants";

// possiveis status:

const apiStatusMap = {
  PENDINGPAYMENT: 0,
  PENDING: 0,
  APPROVED: 1,
  QUEUED: 1,
  DELIVERING: 2, // tela de acompanhamento do entregador
  FINISHED: 3, // termina o pedido
  CANCELLED: 3,
  REJECTED: 3,
};

const OrderConfirmationStatus = () => {
  const address = useSelector(
    (state: RootState) => state.activeOrder.order?.delivery_address
  );

  const { token } = useSelector((state: RootState) => state.auth);
  const activeOrder = useSelector((state: RootState) => state.activeOrder);
  const { orderStatus } = useOrderStatus();
  const [viewCodeDelivery, setViewCodeDelivery] = useState(false);
  // const { newMessageCount } = useChat();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(shoppingCartActions.cleanShoppingCart());
  }, [dispatch]);

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, [token, navigate]);

  const next = () => {
    activeOrder?.order?.status === "DELIVERING"
      ? setViewCodeDelivery(true)
      : navigate("/");
  };

  const activeStep =
    apiStatusMap[orderStatus as keyof typeof apiStatusMap] || 0;

  useEffect(() => {
    if (activeStep === 3) {
      console.log("redirecionando para tela inicial", { activeStep });
      navigate("/");
    }
    // eslint-disable-next-line
  }, [activeStep]);

  // TODO: acompanhamento de status do pedido com websocket

  const steps = [
    "confirmando seu pedido",
    "preparando seu pedido",
    "seu pedido saiu para entrega",
    orderStatus === "FINISHED" ? "pedido entregue" : "pedido cancelado",
  ];

  let estimatedTime = "Em até 1h";
  if (activeOrder.order) {
    estimatedTime = `Hoje às ${format(
      new Date(activeOrder.order.created_at).getTime() + 3540000,
      "H:mm"
    )}`;
  }

  // useEffect(() => {
  //   // @ts-ignore
  //   activeStep === 4 && navigate("/order-delivery");
  //   // eslint-disable-next-line
  // }, [activeStep]);
  //Não estamos usando essa tela

  // const goToChat = () => {
  //   // @ts-ignore
  //   navigate("/order-chat");
  // };

  return (
    <>
      <Layout>
        <AnimatedPage>
          <CodeDelivery open={viewCodeDelivery} setOpen={setViewCodeDelivery} />
          <Box
            height="100%"
            width="100%"
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: "40px",
              paddingTop: "25px",
            }}
          >
            <StepperStatus
              activeStep={activeStep}
              steps={steps}
              orderStatus={orderStatus}
            />
            <BoxInfo
              image={images.marker}
              title={texts.address}
              content={address}
            />
            <BoxInfo
              image={images.clock}
              title={texts.delivery}
              content={estimatedTime}
            />
          </Box>
          <div
            style={{
              marginRight: "287px",
            }}
          >
            {/* <Badge badgeContent={newMessageCount} color="error">
          <>
            <ModalFinishOrderConfirmation open={open} setOpen={setOpen} />
            <Box
              height="100%"
              width="100%"
              sx={{
                display: "flex",
                flexDirection: "column",
                paddingBottom: "40px",
                paddingTop: "25px",
              }}
            >
              <StepperStatus activeStep={activeStep} steps={steps} />
              <BoxInfo
                image={images.marker}
                title={texts.address}
                content={address}
              />
              <BoxInfo
                image={images.clock}
                title={texts.delivery}
                content={estimatedTime}
              />
            </Box>
            <div
              style={{
                marginRight: "287px",
              }}
            >
              {/* <Badge badgeContent={newMessageCount} color="error">
              <Button
                onClick={goToChat}
                // @ts-ignore
                disabled={activeStep !== 3 ? true : false}
              >
                <img
                  alt="Abrir chat com entregador"
                  src={
                    // @ts-ignore
                    activeStep === 3
                      ? images.chatMessage
                      : images.chatMessageDisable
                  }
                />
              </Button>
            </Badge> */}
          </div>
        </AnimatedPage>
      </Layout>
      {/* <LinearProgressLoading /> */}
      <NavigationButtons
        rightTitle={
          activeOrder?.order?.status === "DELIVERING"
            ? "ver código de entrega"
            : texts.next
        }
        rightAction={next}
        removeArrows
      />
    </>
  );
};

export default OrderConfirmationStatus;
