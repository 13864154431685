import { Grid, Typography } from "@mui/material";
import { format } from "date-fns";
import { FC, useState } from "react";

import { useInterval } from "usehooks-ts";
import { color_theme } from "../../color-theme";
import { images, texts } from "./constants";

interface DeliveryProps {
  outOfServiceArea: boolean;
}
const Delivery: FC<DeliveryProps> = ({ outOfServiceArea }) => {
  const [date, setDate] = useState(new Date());

  useInterval(() => {
    setDate(new Date());
  }, 10000);

  const estimatedTime = format(date.getTime() + 1711000, "H:mm");

  return (
    <Grid
      sx={{
        width: "100%",
        height: 55,
        backgroundColor: outOfServiceArea
          ? "#EC3734"
          : color_theme.background.black.light,
        zIndex: 0,
      }}
    >
      <Typography
        sx={{
          display: "flex",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
          fontWeight: 600,
          color: color_theme.text.white.main,
          lineHeight: "19.5px",
          textAlign: "center",
          fontFamily: "Montserrat",
        }}
      >
        {outOfServiceArea ? (
          <>
            <img
              alt="endereço fora de área de entrega"
              src={images.errorCircle}
              style={{ marginRight: "8px" }}
            />
            {texts.outOfArea}
          </>
        ) : (
          `${texts.orderPrevious} ${estimatedTime}`
        )}
      </Typography>
    </Grid>
  );
};
export default Delivery;
